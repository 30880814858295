<template>
  <div class="mainTem">

   
   <div class="frameCon" style="text-align: center; padding: 100px 0; line-height: 100px;font-weight: bold; font-size: 60px; color: #999;">
     <h1>404</h1>
     <p>{{$t('i18nn_0f03037ecaf1990d')}}</p>
     <span>
       <el-button type="primary" size="small" @click="goBack()" icon="el-icon-back">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>
			 <el-button type="warning" size="small" @click="goLogin()" icon="el-icon-arrow-right">{{$t('i18nn_d25a9b29d0bb390b')}}</el-button>
     </span>
     <!-- <span>
       <el-button type="primary" plain size="small" @click="contactService()" icon="el-icon-service">{{$t('i18nn_e097bb9b1216bfd8')}}</el-button>
     </span> -->
   </div>
  </div>


</template>

<script>


export default {

  name: 'NotFound',
  
  //meta信息seo用
  // metaInfo: {
  //     title: '互易天下-厂家共享平台-找不到页面', // set a title
  //     // meta: [{                 // set meta
  //     //   name: 'description',
  //     //   content: '互易天下-厂家共享平台-找不到页面'
  //     // }],
  //     // link: [{                 // set link
  //     //   rel: 'asstes',
  //     //   href: 'https://assets-cdn.github.com/'
  //     // }]
  //   },
  data () {
    return {
      // form: {
      //     name: '',
      //     region: '',
      //     date1: '',
      //     date2: '',
      //     delivery: false,
      //     type: [],
      //     resource: '',
      //     desc: ''
      //   }
    }
  },
  methods: {
      //返回
    goBack() {
      // console.log(this.$t('i18nn_e097bb9b1216bfd8'));
      // qimoChatClick();、
      this.$router.go(-1)
    },
		goLogin() {
		  // console.log(this.$t('i18nn_e097bb9b1216bfd8'));
		  // qimoChatClick();、
		  // this.$router.go(-1)
		  this.$router.push({name:'login'})
		},
      //联系客服
    contactService(row) {
      console.log(this.$t('i18nn_e097bb9b1216bfd8'));
      // this.$Utils.qimoChatClick();
    },
      // onSubmit() {
      //   this.$router.push({name:'PurchaseHall'});
      // }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>
